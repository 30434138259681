import { useCallback, useEffect } from "react";
import { BlockerFunction, useBlocker } from "react-router-dom";
import { formatISO } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { Add, Search } from "@mui/icons-material";
import {
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { makePowerSearchQuery } from "src/utils/makePowerSearchQuery";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { usePowerSearchQuery } from "src/api/usePowerSearchQuery";
import { usePowerSearchSharedForm } from "src/api/usePowerSearchSharedForm";
import { MoreOptionsButton } from "src/components/buttons/MoreOptionsButton";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PowerSearchFormSimple } from "src/components/PowerSearchFormSimple/PowerSearchFormSimple";
import { PowerSearchKeywordForm } from "src/components/PowerSearchKeywordForm/PowerSearchKeywordForm";
import { PowerSearchKeywordFormValues } from "src/components/PowerSearchKeywordForm/PowerSearchKeywordForm.model";
import { PowerSearchKeywordFormWrapper } from "src/components/PowerSearchKeywordForm/PowerSearchKeywordFormWrapper";
import { PowerSearchCustomQueryForm } from "src/components/PowerSearchCustomQueryForm/PowerSearchCustomQueryForm";
import { PowerSearchCustomQueryFormValues } from "src/components/PowerSearchCustomQueryForm/PowerSearchCustomQueryForm.model";
import { PowerSearchCustomQueryFormWrapper } from "src/components/PowerSearchCustomQueryForm/PowerSearchCustomQueryFormWrapper";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { SwitchToSimpleModeModal } from "src/components/SwitchToSimpleModeModal/SwitchToSimpleModeModal";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { powerSearchResultRoute } from "src/pages/PowerSearchResult/PowerSearchResult.route";
import { powerSearchResultAdvancedRoute } from "src/pages/PowerSearchResultAdvanced/PowerSearchResultAdvanced.route";
import { Tabs, usePowerSearchForms } from "./PowerSearch.hook";

type PowerSearchFormValues =
  | PowerSearchKeywordFormValues
  | PowerSearchCustomQueryFormValues;

function countActiveListOptions(formValues: PowerSearchFormValues) {
  return Object.values(formValues).reduce((res, next) => {
    if (!next || typeof next === "string") return res;

    if (next && next instanceof Date) {
      return res + 1;
    }

    return res + next.list.length;
  }, 0);
}

export function PowerSearchPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const watchQuerySearchDialog = useGlobalSearchOpen();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabMode, setTabMode] = useViewModeQueryParam<Tabs>({
    paramKey: "tab",
    defaultValue: Tabs.simpleMode,
  });

  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSuccess() {
        navigate(watchListRoute.path);
      },
    },
  });

  const powerSearchQuery = usePowerSearchQuery();

  const {
    keyWordFormHook,
    customQueryFormHook,
    formValues,
    formState,
    handleSubmit,
    resetForm,
    resetCustomQueryValues,
  } = usePowerSearchForms();

  const { isValid, errors } = formState;

  const { clearSharedValues } = usePowerSearchSharedForm();

  useEffect(() => {
    return clearSharedValues;
  }, [clearSharedValues]);

  const shouldBlockLeaving = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      const { watch } = customQueryFormHook;
      return (
        !!watch().customQuery &&
        nextLocation.search.includes(Tabs.simpleMode) &&
        currentLocation.search !== nextLocation.search
      );
    },
    [customQueryFormHook]
  );
  const blocker = useBlocker(shouldBlockLeaving);

  const switchToSimpleMode = useCallback(() => {
    resetCustomQueryValues();
    blocker.proceed?.();
  }, [blocker, resetCustomQueryValues]);

  const isClearOptionsButtonAvailable =
    countActiveListOptions(formValues) !== 0;

  const submitWatchQueryCreate = handleSubmit(
    (data: PowerSearchFormValues) => {
      const query = makeQueryDefinition(data);
      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchTerm:error", errors);
    }
  );

  const submitPowerSearch = handleSubmit(
    (data: PowerSearchFormValues) => {
      const { startDateTime, endDateTime, ...form } = data;
      powerSearchQuery.save(makePowerSearchQuery(form));
      const route =
        tabMode === Tabs.simpleMode
          ? powerSearchResultRoute
          : powerSearchResultAdvancedRoute;
      const url = route.makeUrl(
        {},
        {
          startDate: startDateTime ? formatISO(startDateTime) : "",
          endDate: endDateTime ? formatISO(endDateTime) : "",
        }
      );
      navigate(url);
    },
    (errors) => {
      console.log("@@ DEBUG:submitPowerSearch:error", errors);
    }
  );

  const clearOptionsButton = (
    <Button
      variant="outlined"
      onClick={resetForm}
      sx={{ width: 164, height: 42 }}
      children="Clear options"
    />
  );

  const newTermButton = (
    <Button
      startIcon={<Add />}
      variant="contained"
      color="info"
      onClick={submitWatchQueryCreate}
      type="submit"
      disabled={!isValid || !isEmpty(errors)}
      children="Watch Term"
      sx={{
        width: !isMobile ? 164 : undefined,
        height: isMobile ? 36 : undefined,
      }}
    />
  );

  const searchButton = (
    <Button
      startIcon={<Search />}
      variant="contained"
      color="primary"
      disabled={!isValid || !isEmpty(errors)}
      onClick={submitPowerSearch}
      sx={{ width: 164, height: 42 }}
    >
      Search
    </Button>
  );

  const headerDesktop = (
    <Stack flexWrap="wrap" direction="row" mb={3} alignItems="center">
      <Typography flexGrow={1} variant="h6" mr={2}>
        Power Search
      </Typography>

      <Stack direction="row" spacing={2}>
        {isClearOptionsButtonAvailable && clearOptionsButton}
        {newTermButton}
        {searchButton}
      </Stack>
    </Stack>
  );

  const desktopContent = (
    <Stack height="100%" overflow="hidden">
      <Stack overflow="auto" component={Paper} borderRadius={0}>
        <AppTabLayout
          id="tabs-mode"
          flex={1}
          overflow="auto"
          variant="standard"
          currentTab={tabMode}
          onCurrentTabChange={(tab) => setTabMode(tab as Tabs)}
        >
          <AppTab
            label="Simple mode"
            value={Tabs.simpleMode}
            children={
              <FormProvider {...keyWordFormHook}>
                <PowerSearchKeywordFormWrapper>
                  <PowerSearchKeywordForm />
                </PowerSearchKeywordFormWrapper>
              </FormProvider>
            }
          />
          <AppTab
            label="Advanced mode"
            value={Tabs.advancedMode}
            children={
              <FormProvider {...customQueryFormHook}>
                <PowerSearchCustomQueryFormWrapper>
                  <PowerSearchCustomQueryForm />
                </PowerSearchCustomQueryFormWrapper>
              </FormProvider>
            }
          />
        </AppTabLayout>
      </Stack>
    </Stack>
  );

  const mobileContent = (
    <Stack mt="15vh" mx="auto" maxWidth={337} rowGap={3}>
      <Typography variant="h5" align="center">
        Search for Mention <br /> on TV, Radio, or Podcasts
      </Typography>

      <PowerSearchFormSimple />

      <MoreOptionsButton
        id="power-search-more-options-button"
        onClick={watchQuerySearchDialog.show}
        sx={{ alignSelf: "center" }}
      />

      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
        initial={keyWordFormHook.watch()}
      />
    </Stack>
  );

  if (isMobile) {
    return (
      <Paper sx={{ width: "100%" }}>
        <FormProvider {...keyWordFormHook}>
          <PageLayoutMobile content={mobileContent} hideGlobalSearchBtn />
        </FormProvider>
      </Paper>
    );
  }

  return (
    <>
      <PageLayoutDesktop
        header={headerDesktop}
        content={desktopContent}
        overflow="hidden"
      />
      {blocker && (
        <SwitchToSimpleModeModal
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset?.()}
          title={"Switch to Simple Mode?"}
          onSwitch={switchToSimpleMode}
        />
      )}
    </>
  );
}
