import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";

const apiKey: keyof paths = "/api/Report/scratch/matches";
const method: keyof paths[typeof apiKey] = "get";

type Endpoint = paths[typeof apiKey][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];
export const reportScratchMatches = "report-scratch-matches";

export function useReportScratch() {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();

  return useQuery<Response, unknown>({
    queryKey: reportScratchMatches,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey, {
        path: {
          userid: userId,
        },
      });
      const { data } = await axios<Response>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
