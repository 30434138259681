import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Alert,
  Badge,
  FormControlLabel,
  IconButton,
  LabelDisplayedRowsArgs,
  Paper,
  Snackbar,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  alertClasses,
  badgeClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Edit from "@mui/icons-material/Edit";
import { GridPaginationModel } from "@mui/x-data-grid-premium";
import {
  ArrayParam,
  BooleanParam,
  NumericArrayParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { PowerSearchResultItem, usePowerSearch } from "src/api/usePowerSearch";
import { usePowerSearchQuery } from "src/api/usePowerSearchQuery";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useEventSelectionPowerSearchResults } from "src/api/useEventSelectionPowerSearchResults";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { useReportScratchAppend } from "src/api/useReportScratchAppend";
import { useReportCreate } from "src/api/useReportCreate";
import { useReportCreateFromPowersearch } from "src/api/useReportCreateFromPowersearch";
import {
  useShareEventFormSnackbar,
  useShareEventLinkSnackbar,
} from "src/api/useShareEventSnackbar";
import { useReportScratchAppendFromPowersearch } from "src/api/useReportScratchAppenFromPowersearch";

// every commented line here is related to MMSR-2997
// import { useExportPdf } from "src/api/useExportPdf";
import { useExportCsv } from "src/api/useExportCsv";
import { useIntercomButtonState } from "src/api/useIntercomButtonState";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
import { usePowerSearchInfinite } from "src/api/usePowerSearchInfinite";
import { useListFilter } from "src/utils/useListFilter";
import { useOpenState } from "src/utils/useOpenState";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { countQueryFilters } from "src/utils/countQueryFilters";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { makePowerSearchCustomQuery } from "src/utils/makePowerSearchQuery";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import { SearchInput } from "src/components/SearchInput";
import {
  SortBy,
  SortByParameters,
  OrderByParameters,
  SortOption,
} from "src/components/SortingMenu/SortingMenu.model";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { PowerSearchFilterDialogAdvanced } from "src/components/PowerSearchFilterDialogAdvanced/PowerSearchFilterDialogAdvanced";
import { ReportCreateDialog } from "src/components/ReportCreateDialog/ReportCreateDialog";
import { SnackbarBase } from "src/components/SnackbarBase/SnackbarBase";
import { PreselectEventsButton } from "src/components/PreselectEventsButton/PreselectEventsButton";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import { ShareEventsDialog } from "src/components/ShareEventsDialog/ShareEventsDialog";
import { EventsResultsExportButton } from "src/components/EventsResultsExportButton/EventsResultsExportButton";
import { ShareButtonDesktop } from "src/components/ShareButtonDesktop/ShareButtonDesktop";
import { ReportCreateFormValues } from "src/components/ReportCreateForm/ReportCreateForm.model";
import { SelectAllPages } from "src/components/SelectAllPages/SelectAllPages";
import { DownloadFilesSnackbar } from "src/components/DownloadFilesSnackbar/DownloadFilesSnackbar";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { DuplicatesDrawer } from "src/components/DuplicatesDrawer/DuplicatesDrawer";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { CustomQueryEditDialog } from "src/components/CustomQueryEditDialog/CustomQueryEditDialog";
import { DuplicatesPaginationLabel } from "src/components/DuplicatesPaginationLabel/DuplicatesPaginationLabel";
import { usePowerSearchResultDataView } from "src/pages/PowerSearchResult/PowerSearchResult.hooks";
import { powerSearchRoute } from "src/pages/PowerSearch/PowerSearch.route";
import usePreselectLimit from "src/utils/usePreselectedLimit";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function PowerSearchResultAdvancedPage() {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<SortByParameters>("default");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const [isExpandedTextMode = false, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );
  const [groupDuplicates, setGroupDuplicates] = useQueryParam(
    "grouped",
    withDefault(BooleanParam, true)
  );
  const duplicatesDrawerState = useOpenState();
  const [drawerParentEventId, setDrawerParentEventId] = useState<string>();
  const [duplicatesIds, setDuplicatesIds] = useState<string[]>([]);
  const [duplicatesSortBy, duplicatesSetSortBy] =
    useState<SortByParameters>("default");
  const [duplicatesOrderBy, duplicatesSetOrderBy] =
    useState<OrderByParameters>("desc");

  const intercomButtonState = useIntercomButtonState();

  const [keepPreviousData, setKeepPreviousData] = useState(true);
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode = initialViewMode, setViewMode] =
    useQueryParam<ViewMode>("tab");

  const { isOpen, show, hide } = useOpenState();

  const customQueryEditDialog = useOpenState();
  const { maxEventsToPreselect } = usePreselectLimit();

  const reportCreateDialog = useOpenState();
  const reportCreateSnackBar = useOpenState();
  const downloadFilesSnackBar = useDownloadFilesSnackbar();
  const shareDialog = useOpenState();
  const shareFormSnackBar = useShareEventFormSnackbar();
  const shareLinkSnackBar = useShareEventLinkSnackbar();

  const powerSearchQuery = usePowerSearchQuery();

  const [dateTimeRange, setDateTimeRange] = useQueryDateRangeWithEmptyDefault();
  const [pagination, setPagination] = useQueryPagination();
  const [paginationDrawer, setPaginationDrawer] = useQueryPagination({
    pageKey: "pageDrawer",
    pageSizeKey: "pageSizeDrawer",
  });

  /** is used for switching between pages while groupDuplicates is set to true */
  const [pagesHistory, setPagesHistory] = useQueryParam(
    "groupedPages",
    withDefault(ArrayParam, [""])
  );
  /** "after" is overriding "from" on the BE side, is used with groupDuplicates set to true */
  const after = pagesHistory[pagesHistory.length - 1];

  /** is used for the displayed rows label in the pagination while groupDuplicates is set to true */
  const [duplicatesCounts, setDuplicatesCounts] = useQueryParam(
    "duplicatesCounts",
    withDefault(NumericArrayParam, [])
  );

  const pageEventsState = useEventSelectionPowerSearchResults();
  const duplicatesDrawerEventsState = useEventSelectionDuplicatesDrawer();

  useEffect(() => {
    if (isMobile) {
      navigate(powerSearchRoute.path);
    }
  }, [navigate, isMobile]);

  useEffect(() => {
    if (!powerSearchQuery.data.customQuery) {
      navigate(powerSearchRoute.path);
    }
  }, [navigate, powerSearchQuery.data.customQuery]);

  const reportScratchAppend = useReportScratchAppend({});
  const reportScratchAppendFromQuery = useReportScratchAppendFromPowersearch({
    params: {
      query: {
        startTime: dateTimeRange[0]
          ? formatDateTimeWithoutTimeZone(dateTimeRange[0])
          : undefined,
        endTime: dateTimeRange[1]
          ? formatDateTimeWithoutTimeZone(dateTimeRange[1])
          : undefined,
        groupDuplicates,
      },
    },
  });

  const { setHighlightedEventsIds, showTooltip, setAllAddedEventsLenght } =
    useEventsPreselected();

  const powerSearchResults = usePowerSearch({
    options: {
      enabled: !!powerSearchQuery.data && viewMode !== ViewMode.tile,
    },
    request: {
      body: powerSearchQuery.data,
      params: {
        query: {
          from: pagination.page * pagination.pageSize,
          size: pagination.pageSize,
          startDate: dateTimeRange[0]
            ? formatDateTimeWithoutTimeZone(dateTimeRange[0])
            : undefined,
          endDate: dateTimeRange[1]
            ? formatDateTimeWithoutTimeZone(dateTimeRange[1])
            : undefined,
          sortby: sortBy,
          orderby: orderBy,
          groupDuplicates: !!groupDuplicates,
          after: groupDuplicates && after ? after : undefined,
        },
      },
    },
    keepPreviousData,
  });

  const powerSearchResultsTiles = usePowerSearchInfinite({
    options: {
      enabled: !!powerSearchQuery.data && viewMode === ViewMode.tile,
    },
    request: {
      params: {
        query: {
          startDate: dateTimeRange[0]
            ? formatDateTimeWithoutTimeZone(dateTimeRange[0])
            : undefined,
          endDate: dateTimeRange[1]
            ? formatDateTimeWithoutTimeZone(dateTimeRange[1])
            : undefined,
          sortby: sortBy,
          orderby: orderBy,
          groupDuplicates: !!groupDuplicates,
        },
      },
      body: powerSearchQuery.data,
    },
    keepPreviousData,
  });

  const powerSearchResultsData = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      return combineInfiniteTilesData(powerSearchResultsTiles.data?.pages);
    }
    return powerSearchResults.data?.results;
  }, [
    viewMode,
    powerSearchResults.data?.results,
    powerSearchResultsTiles.data?.pages,
  ]);

  const totalRowCount = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      const tilesPagesData = powerSearchResultsTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    return powerSearchResults.data?.total || 0;
  }, [
    viewMode,
    powerSearchResultsTiles.data?.pages,
    powerSearchResults.data?.total,
  ]);

  const duplicatesResults = usePowerSearch({
    options: { enabled: !!duplicatesIds.length },
    request: {
      body: powerSearchQuery.data,
      includeFilter: duplicatesIds,
      params: {
        query: {
          from: 0,
          size: duplicatesIds.length,
          sortby: duplicatesSortBy,
          orderby: duplicatesOrderBy,
        },
      },
    },
    keepPreviousData: false,
  });

  const duplicatesCountCurrentPage = powerSearchResults.data?.results
    ? powerSearchResults.data?.results.reduce((sum, event) => {
        return sum + event.duplicates.length;
      }, 0)
    : 0;

  const setPaginationWrapper = useCallback(
    (nextPagination: GridPaginationModel) => {
      if (groupDuplicates) {
        if (nextPagination.pageSize !== pagination.pageSize) {
          // change page size:
          setPagesHistory([""], "replaceIn");
          setDuplicatesCounts([], "replaceIn");
        }
        const nextPage = powerSearchResults.data?.configuration?.next;
        if (nextPagination.page > pagination.page && nextPage) {
          // go to the next page:
          setPagesHistory([...pagesHistory, nextPage], "replaceIn");
          setDuplicatesCounts(
            [...duplicatesCounts, duplicatesCountCurrentPage],
            "replaceIn"
          );
        }
        if (nextPagination.page < pagination.page) {
          // go to the previous page:
          setPagesHistory(pagesHistory.slice(0, -1), "replaceIn");
          setDuplicatesCounts(duplicatesCounts.slice(0, -1), "replaceIn");
        }
      }
      setPagination(nextPagination);
    },
    [
      groupDuplicates,
      pagination,
      setPagination,
      setPagesHistory,
      powerSearchResults.data?.configuration?.next,
      pagesHistory,
      duplicatesCountCurrentPage,
      duplicatesCounts,
      setDuplicatesCounts,
    ]
  );

  const navToTheFirstPage = useCallback(() => {
    setPagesHistory([""], "replaceIn");
    setDuplicatesCounts([], "replaceIn");
    setPagination({
      ...pagination,
      page: 0,
    });
  }, [setPagesHistory, setDuplicatesCounts, setPagination, pagination]);

  useEffect(() => {
    if (keepPreviousData === false) {
      if (
        (viewMode === ViewMode.tile && powerSearchResultsTiles.isFetched) ||
        (viewMode !== ViewMode.tile && powerSearchResults.isFetched)
      ) {
        setKeepPreviousData(true);
      }
    }
  }, [
    keepPreviousData,
    viewMode,
    powerSearchResultsTiles.isFetched,
    powerSearchResults.isFetched,
  ]);

  const selectedPageEventsLength = pageEventsState.areAllPagesSelected
    ? totalRowCount - pageEventsState.excludeList.length
    : pageEventsState.list.length ?? 0;

  const selectedEventsIncludingDrawer = useMemo(
    () => [...pageEventsState.list, ...duplicatesDrawerEventsState.list],
    [pageEventsState.list, duplicatesDrawerEventsState.list]
  );

  const selectedDrawerEventsLength = duplicatesDrawerEventsState.list.length;
  const selectedEventsLength =
    selectedPageEventsLength + selectedDrawerEventsLength;

  const [filterText, setFilterText] = useState("");
  const filterData = useListFilter(powerSearchResultsData || [], filterText, [
    "title",
    "highlights",
    "market",
    "country",
    "source",
  ]);

  const reportCreateOptions = {
    onSuccess: () => {
      reportCreateDialog.hide();
      pageEventsState.unselectAll();
      duplicatesDrawerEventsState.unselectAll();
      reportCreateSnackBar.show();
    },
  };

  const reportCreateMutation = useReportCreate({
    options: reportCreateOptions,
  });

  const reportCreateFromPowersearchMutation = useReportCreateFromPowersearch({
    options: reportCreateOptions,
  });

  const excludeFilter = useMemo(() => {
    return pageEventsState.excludeList.reduce((acc: string[], item) => {
      return [...acc, item.id, ...item.duplicates];
    }, []);
  }, [pageEventsState.excludeList]);

  const includeFilter = useMemo(
    () => duplicatesDrawerEventsState.list.map((ev) => ev.id),
    [duplicatesDrawerEventsState.list]
  );

  const createReport = useCallback(
    (data: ReportCreateFormValues) => {
      if (pageEventsState.areAllPagesSelected) {
        reportCreateFromPowersearchMutation.mutate({
          queryDefinition: {
            ...makeQueryDefinition(powerSearchQuery.data),
            excludeFilter,
            includeFilter,
          },
          params: {
            query: {
              startTime: dateTimeRange[0]
                ? formatDateTimeWithoutTimeZone(dateTimeRange[0])
                : undefined,
              endTime: dateTimeRange[1]
                ? formatDateTimeWithoutTimeZone(dateTimeRange[1])
                : undefined,
              title: data.title,
              description: data.description,
              archive: true,
              groupDuplicates,
            },
          },
        });
      } else {
        reportCreateMutation.mutate({
          title: data.title,
          description: data.description,
          selectedEvents: selectedEventsIncludingDrawer,
          archive: true,
        });
      }
    },
    [
      reportCreateMutation,
      pageEventsState,
      dateTimeRange,
      powerSearchQuery.data,
      groupDuplicates,
      selectedEventsIncludingDrawer,
      reportCreateFromPowersearchMutation,
      excludeFilter,
      includeFilter,
    ]
  );

  // const downloadPdfMutation = useExportPdf({
  //   options: {
  //     onSuccess: () => {
  //       pageEventsState.unselectAll();
  //       duplicatesDrawerEventsState.unselectAll();
  //     },
  //   },
  // });

  // const downloadPdf = useCallback(() => {
  //   downloadFilesSnackBar.show();
  //   downloadPdfMutation.mutate({
  //     selectedEvents: selectedEventsIncludingDrawer,
  //   });
  // }, [
  //   downloadPdfMutation,
  //   downloadFilesSnackBar,
  //   selectedEventsIncludingDrawer,
  // ]);

  const downloadCsvMutation = useExportCsv({
    options: {
      onSuccess: () => {
        pageEventsState.unselectAll();
        duplicatesDrawerEventsState.unselectAll();
      },
    },
  });

  const downloadCsv = useCallback(() => {
    downloadFilesSnackBar.show();
    downloadCsvMutation.mutate({
      selectedEvents: selectedEventsIncludingDrawer,
    });
  }, [
    downloadCsvMutation,
    downloadFilesSnackBar,
    selectedEventsIncludingDrawer,
  ]);

  const onSortChange = (sortBy: SortOption) => {
    setSortOption(sortBy.value);
    setSortBy(sortBy.field);
    setOrderBy(sortBy.order);
  };

  const hasFilters =
    Number(
      countQueryFilters(makePowerSearchCustomQuery(powerSearchQuery.data))
    ) > 0;

  const filterButton = useMemo(
    () => (
      <Badge
        color="primary"
        badgeContent={countQueryFilters(
          makePowerSearchCustomQuery(powerSearchQuery.data)
        )}
        sx={{
          [`.${badgeClasses.badge}`]: {
            width: isMobile ? 20 : 26,
            height: isMobile ? 20 : 26,
          },
        }}
      >
        <ToggleButtonGroup size={isMobile ? "small" : "medium"}>
          <ToggleButton value={true} onClick={show}>
            <TuneIcon sx={{ color: "text.primary" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Badge>
    ),
    [show, isMobile, powerSearchQuery.data]
  );

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        pageEventsState.selectAll(filterData);
      } else {
        pageEventsState.unselectAll();
      }
    },
    [pageEventsState, filterData]
  );

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: pageEventsState.list,
    areAllPagesSelected: pageEventsState.areAllPagesSelected,
    excludeEventsLength: pageEventsState.excludeList?.length,
  });

  const hasMorePages = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      return totalRowCount > filterData.length;
    }
    return totalRowCount / pagination.pageSize > 1;
  }, [viewMode, totalRowCount, filterData.length, pagination.pageSize]);

  const selectAllPagesIsShown = isAllSelected && hasMorePages;

  const renderAllCheckboxSelector = useCallback(
    ({ hideLabel }: { hideLabel?: boolean }) => {
      if ([ViewMode.tile, ViewMode.list].includes(viewMode)) {
        return (
          <AllCheckboxSelector
            checked={isAllSelected || isPartiallySelected}
            isPartiallySelected={isPartiallySelected}
            onChange={handleChangeAllCheckbox}
            sx={{ ml: viewMode === ViewMode.tile ? "12px" : "28px" }}
            hideLabel={hideLabel}
          />
        );
      }
      return null;
    },
    [handleChangeAllCheckbox, isAllSelected, isPartiallySelected, viewMode]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
          SortBy.viewershipDesc,
          SortBy.viewershipAsc,
          SortBy.disctanceAsc,
          SortBy.distanceDesc,
        ]}
      />
    ),
    [sortOption]
  );

  const onChangeViewMode = useCallback(
    (nextMode: ViewMode) => {
      if (viewMode !== nextMode) {
        pageEventsState.unselectAll();
        duplicatesDrawerEventsState.unselectAll();
      }
      setViewMode(nextMode);
    },
    [viewMode, setViewMode, pageEventsState, duplicatesDrawerEventsState]
  );

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={onChangeViewMode}
        size={isMobile ? "small" : "medium"}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
      />
    ),
    [isMobile, onChangeViewMode, viewMode]
  );

  const addItemToReport = useCallback(
    (item: PowerSearchResultItem) => {
      reportScratchAppend.mutate(
        { events: [item] },
        {
          onSuccess: () => {
            enqueueSnackbar(
              `${item.title} was successfully pre selected and sent to the "Report pre selected" page, now you can create a new Report from it`,
              {
                variant: "success",
              }
            );
            setHighlightedEventsIds([item.id]);
          },
        }
      );
    },
    [reportScratchAppend, setHighlightedEventsIds, enqueueSnackbar]
  );

  const preselectEvents = useCallback(() => {
    const successMessage =
      'Events were successfully pre selected and sent to the "Report pre selected" page, now you can create a new Report from them';

    if (pageEventsState.areAllPagesSelected) {
      reportScratchAppendFromQuery.mutate(
        {
          query: {
            ...makeQueryDefinition(powerSearchQuery.data),
            excludeFilter,
            includeFilter,
          },
        },
        {
          onSuccess: () => {
            enqueueSnackbar(successMessage, {
              variant: "success",
            });
            setAllAddedEventsLenght(selectedEventsLength);
            showTooltip();
          },
        }
      );
    } else {
      reportScratchAppend.mutate(
        { events: selectedEventsIncludingDrawer },
        {
          onSuccess: () => {
            enqueueSnackbar(successMessage, {
              variant: "success",
            });
            setHighlightedEventsIds(
              selectedEventsIncludingDrawer.map((event) => event.id)
            );
            showTooltip();
          },
        }
      );
    }
    pageEventsState.unselectAll();
    duplicatesDrawerEventsState.unselectAll();
  }, [
    enqueueSnackbar,
    reportScratchAppend,
    reportScratchAppendFromQuery,
    setHighlightedEventsIds,
    showTooltip,
    selectedEventsIncludingDrawer,
    powerSearchQuery.data,
    selectedEventsLength,
    setAllAddedEventsLenght,
    pageEventsState,
    duplicatesDrawerEventsState,
    excludeFilter,
    includeFilter,
  ]);

  const filterInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
        fullWidth
      />
    ),
    [isMobile]
  );

  const showDuplicatesDrawer = useCallback(
    (eventId: string, duplicatesIds: string[]) => {
      setDrawerParentEventId(eventId);
      setDuplicatesIds(duplicatesIds);
      duplicatesDrawerState.show();
      intercomButtonState.hide();
    },
    [duplicatesDrawerState, intercomButtonState]
  );

  const hideDuplicatesDrawer = useCallback(() => {
    setDrawerParentEventId("");
    duplicatesDrawerState.hide();
    intercomButtonState.show();
    setPaginationDrawer({
      ...paginationDrawer,
      page: 0,
    });
  }, [
    duplicatesDrawerState,
    intercomButtonState,
    paginationDrawer,
    setPaginationDrawer,
  ]);

  const onGroupDuplicatesSwitchChange = useCallback(
    (_e: ChangeEvent, checked: boolean) => {
      setKeepPreviousData(false);
      navToTheFirstPage();
      setGroupDuplicates(checked, "replaceIn");
      pageEventsState.unselectAll();
      duplicatesDrawerEventsState.unselectAll();
    },
    [
      navToTheFirstPage,
      setGroupDuplicates,
      pageEventsState,
      duplicatesDrawerEventsState,
    ]
  );

  const groupDuplicatesSwitch = useMemo(
    () => (
      <FormControlLabel
        label={<Typography variant="caption">Group duplicates</Typography>}
        sx={{ ml: 2 }}
        control={
          <Switch
            size="small"
            checked={!!groupDuplicates}
            onChange={onGroupDuplicatesSwitchChange}
          />
        }
      />
    ),
    [groupDuplicates, onGroupDuplicatesSwitchChange]
  );

  const expandedTextSwitch = useMemo(
    () => (
      <FormControlLabel
        checked={!!isExpandedTextMode}
        control={
          <Switch
            size={isMobile ? "medium" : "small"}
            onChange={(_e, checked) =>
              setExpandedTextMode(checked, "replaceIn")
            }
          />
        }
        label={<Typography variant="caption">Expanded Text</Typography>}
        sx={{ mx: 0, whiteSpace: "nowrap" }}
      />
    ),
    [isExpandedTextMode, setExpandedTextMode, isMobile]
  );

  const renderCustomFooterEl = useCallback(() => {
    if (isMobile) {
      return null;
    }
    return (
      <Stack direction="row">
        {groupDuplicatesSwitch}
        {viewMode !== ViewMode.table && expandedTextSwitch}
      </Stack>
    );
  }, [isMobile, groupDuplicatesSwitch, expandedTextSwitch, viewMode]);

  const renderLabelDisplayedRows = useCallback(
    (paginationInfo: LabelDisplayedRowsArgs) => {
      const { count, page } = paginationInfo;
      // duplicatesCountsCopy - is a fix for the typescript error
      const duplicatesCountsCopy: Array<(typeof duplicatesCounts)[number]> =
        duplicatesCounts;
      const duplicatesSumPrevious = duplicatesCountsCopy.reduce(
        (acc, count) => {
          return (acc || 0) + (count || 0);
        },
        0
      );
      const fromWithDuplicates =
        page * pagination.pageSize + (duplicatesSumPrevious || 0) + 1;
      const to = fromWithDuplicates + pagination.pageSize - 1;
      return (
        <DuplicatesPaginationLabel
          from={fromWithDuplicates}
          to={to}
          duplicatesCount={duplicatesCountCurrentPage}
          totalCount={count}
        />
      );
    },
    [duplicatesCountCurrentPage, duplicatesCounts, pagination.pageSize]
  );

  const setDateRangeWrapper = useCallback(
    (dateRange: DateRange) => {
      setDateTimeRange(dateRange);
      navToTheFirstPage();
    },
    [setDateTimeRange, navToTheFirstPage]
  );

  const renderSelectAll = useCallback(() => {
    if (!selectAllPagesIsShown) return null;
    return (
      <SelectAllPages
        rowCount={totalRowCount}
        selectedCount={
          pageEventsState.areAllPagesSelected
            ? totalRowCount
            : pageEventsState.list.length
        }
        areAllPagesSelected={pageEventsState.areAllPagesSelected}
        setAllPagesSelected={pageEventsState.setAllPagesSelected}
        unselectAll={pageEventsState.unselectAll}
        allCheckboxSelector={renderAllCheckboxSelector({ hideLabel: true })}
        groupDuplicates={groupDuplicates}
      />
    );
  }, [
    selectAllPagesIsShown,
    pageEventsState,
    totalRowCount,
    renderAllCheckboxSelector,
    groupDuplicates,
  ]);

  const nextPageButtonIsDisabled = useMemo(() => {
    if (groupDuplicates) {
      const loading =
        powerSearchResults.isLoading || powerSearchResults.isFetching;
      const nextPage = powerSearchResults.data?.configuration?.next;
      if (loading || !nextPage) return true;
    }
    return false;
  }, [
    groupDuplicates,
    powerSearchResults.isLoading,
    powerSearchResults.isFetching,
    powerSearchResults.data?.configuration?.next,
  ]);

  const dataViewElement = usePowerSearchResultDataView({
    data: filterData,
    viewMode,
    loading: powerSearchResults.isLoading || powerSearchResults.isFetching,
    isMobile,
    isExpandedTextMode: !!isExpandedTextMode,
    rowCount: totalRowCount,
    pagination,
    setPagination: setPaginationWrapper,
    addItemToReport,
    renderSelectAll,
    powerSearchResultsTiles,
    rows: filterData,
    renderCustomFooterEl,
    nextPageButtonIsDisabled,
    showDuplicatesDrawer,
    drawerParentEventId,
    renderLabelDisplayedRows: groupDuplicates
      ? renderLabelDisplayedRows
      : undefined,
    hasFilters: hasFilters,
  });

  const handleShareDialogSubmit = () => {
    pageEventsState.unselectAll();
    duplicatesDrawerEventsState.unselectAll();
    shareFormSnackBar.show();
  };

  const copyShareLinkCallback = () => {
    pageEventsState.unselectAll();
    duplicatesDrawerEventsState.unselectAll();
    shareLinkSnackBar.show();
  };

  const showExportShareButtons = selectedEventsLength > 0;

  const headerDesktop = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      flexWrap="wrap"
      columnGap={1}
    >
      <Stack alignItems="flex-start">
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => navigate(powerSearchRoute.path)}
            edge="start"
          >
            <ArrowBack />
          </IconButton>
          <Tooltip title={powerSearchQuery.data.displayName || "loading"}>
            <TextLineClamp variant="h6" lineClamp={1} mx={2}>
              {powerSearchQuery.data.displayName}
            </TextLineClamp>
          </Tooltip>
          <Tooltip title={"Edit Custom Query"}>
            <IconButton onClick={customQueryEditDialog.show}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>
        <AppTopNavBar
          sx={{ mb: 2 }}
          hidePenultimateRoute
          routeLabels={{
            "2": "Power Search",
            "0": "Search Results",
          }}
        />
      </Stack>

      <Stack
        direction="row"
        columnGap={2}
        mb={2}
        flex={1}
        justifyContent="flex-end"
      >
        <PreselectEventsButton
          badgeCounter={selectedEventsLength}
          disabled={
            !selectedEventsLength || selectedEventsLength > maxEventsToPreselect
          }
          onClick={preselectEvents}
        />
        <EventsResultsExportButton
          disabled={!showExportShareButtons}
          value={selectedEventsIncludingDrawer}
          selectedEventsLength={selectedEventsLength}
          showCreateReport={reportCreateDialog.show}
          // downloadPdf={downloadPdf}
          downloadCsv={downloadCsv}
        />
        <ShareButtonDesktop
          disabled={!showExportShareButtons}
          showShareDialog={shareDialog.show}
          badgeContent={selectedEventsLength}
        />
      </Stack>
    </Stack>
  );

  const toolbarDesktop = (
    <Stack
      p={2}
      gap={2}
      direction={{ sm: "column", md: "row" }}
      justifyContent="space-between"
    >
      <Stack direction="row" flex={1}>
        {renderAllCheckboxSelector({})}
        <Stack
          sx={{
            justifyContent: "flex-end",
            mr: 2,
          }}
        >
          {filterInput}
        </Stack>
        <DateTimeSelector
          value={dateTimeRange}
          onChange={setDateRangeWrapper}
          views={["year", "month", "day", "hours", "minutes"]}
          mode={DateTimeSelectorMode.variable}
          sx={{ flex: 1 }}
          clearable
        />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        {filterButton}
        {sortingMenu}
        {viewModeSwitch}
      </Stack>
    </Stack>
  );

  const desktopContent = (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
      }}
    >
      {!selectAllPagesIsShown && toolbarDesktop}
      {renderSelectAll()}
      <Stack flex={1} overflow="hidden">
        {dataViewElement}
      </Stack>
      <DuplicatesDrawer
        isPowerSearch
        duplicatesData={duplicatesResults.data?.results || []}
        loading={duplicatesResults.isLoading || duplicatesResults.isFetching}
        open={duplicatesDrawerState.isOpen}
        onClose={hideDuplicatesDrawer}
        addItemToReport={addItemToReport}
        pagination={paginationDrawer}
        setPagination={setPaginationDrawer}
        setSortBy={duplicatesSetSortBy}
        setOrderBy={duplicatesSetOrderBy}
      />
    </Paper>
  );

  const commonContent = (
    <Fragment>
      <PowerSearchFilterDialogAdvanced
        open={isOpen}
        onClose={hide}
        onSubmit={() => {
          hide();
          navToTheFirstPage();
        }}
        title="Power search options"
        initial={makePowerSearchCustomQuery(powerSearchQuery.data)}
        mobileFullHeight={isMobile}
        closeOnLeftSide={isMobile}
      />
      <CustomQueryEditDialog
        open={customQueryEditDialog.isOpen}
        onClose={customQueryEditDialog.hide}
        onSubmit={() => {
          customQueryEditDialog.hide();
          navToTheFirstPage();
        }}
        title="Edit Custom Query"
        initial={makePowerSearchCustomQuery(powerSearchQuery.data)}
      />
      {selectedEventsLength > 0 && (
        <ReportCreateDialog
          dateRange={dateTimeRange}
          open={reportCreateDialog.isOpen}
          onClose={reportCreateDialog.hide}
          createReport={createReport}
          isLoading={
            reportCreateMutation.isLoading ||
            reportCreateFromPowersearchMutation.isLoading
          }
        />
      )}

      {selectedEventsLength > 0 && (
        <ShareEventsDialog
          title={selectedEventsLength > 1 ? "Share Events" : "Share Event"}
          open={shareDialog.isOpen}
          events={selectedEventsIncludingDrawer}
          onClose={shareDialog.hide}
          onSubmit={handleShareDialogSubmit}
          copyShareLinkCallback={copyShareLinkCallback}
          areAllPagesSelected={pageEventsState.areAllPagesSelected}
          queryParams={{
            queryDefinition: {
              ...makeQueryDefinition(powerSearchQuery.data),
              excludeFilter,
              includeFilter,
            },
            startTime: dateTimeRange[0]
              ? formatDateTimeWithoutTimeZone(dateTimeRange[0])
              : undefined,
            endTime: dateTimeRange[1]
              ? formatDateTimeWithoutTimeZone(dateTimeRange[1])
              : undefined,
            groupDuplicates,
          }}
          selectedEventsLength={selectedEventsLength}
          isPowerSearch
        />
      )}

      <Snackbar
        open={shareLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={shareLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={shareLinkSnackBar.hide}
          sx={{
            borderRadius: 1,
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          The link to the Events was successfully copied to the clipboard
        </Alert>
      </Snackbar>
      <SnackbarBase
        open={shareFormSnackBar.isOpen}
        onClose={shareFormSnackBar.hide}
        title="Your share is being processed."
        subtitle="You and your recipients will get an email."
      />

      <SnackbarBase
        open={reportCreateSnackBar.isOpen}
        onClose={reportCreateSnackBar.hide}
        title="Your Report is being processed."
        subtitle={
          <>
            It may take some time depending on the amount of items in the
            report.
            <br />
            Finished report appears in your Reports page and your Email
          </>
        }
      />

      <DownloadFilesSnackbar
        open={downloadFilesSnackBar.isOpen}
        onClose={downloadFilesSnackBar.hide}
      />
    </Fragment>
  );

  return (
    <Fragment>
      <PageLayoutDesktop
        header={headerDesktop}
        content={desktopContent}
        overflow="hidden"
      />
      {commonContent}
    </Fragment>
  );
}
