import { useState } from "react";
import {
  Stack,
  Grid,
  Box,
  Alert,
  alertClasses,
  Divider,
  dividerClasses,
  InputAdornment,
  formHelperTextClasses,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "@mui/icons-material";
import { Controller, useFormContext } from "react-hook-form";
import { LoadingButton } from "../buttons/LoadingButton";
import { TextInputBase } from "../TextInputBase";
import { ShareFormValues } from "./ShareForm.model";
import { AppAutocomplete } from "../AppAutocomplete/AppAutocomplete";

type ShareFormProps = {
  hideInstantLink?: boolean;
  instantLinkValue?: string;
  getInstantLink: () => void;
  isLoadingLink?: boolean;
  copyShareLinkCallback: () => void;
  onClose: () => void;
};

export function ShareForm({
  hideInstantLink = false,
  instantLinkValue,
  getInstantLink,
  isLoadingLink = false,
  copyShareLinkCallback,
  onClose,
}: ShareFormProps) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { control } = useFormContext<ShareFormValues>();
  const [recipientsOptions, setRecipientsOptions] = useState<string[]>([]);

  const copyLinkToClipboard = async (instantLinkValue: string) => {
    if (!navigator.clipboard) {
      console.error("browser does not support navigation.clipboard");
    }
    await navigator.clipboard.writeText(instantLinkValue);
    onClose();
    copyShareLinkCallback();
  };

  return (
    <Stack pt={1}>
      {!hideInstantLink && (
        <>
          <Box>
            <TextInputBase
              id="instant-link-input"
              label="Instant link"
              fullWidth
              size="small"
              placeholder="Instant link"
              value={instantLinkValue || ""}
              InputProps={{
                readOnly: true,
                sx: {
                  pr: 0,
                  mb: isMobile ? 2 : 3,
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadingButton
                      variant="contained"
                      color="info"
                      sx={{
                        width: isMobile ? 100 : 125,
                        height: 40,
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                      }}
                      loading={!isMobile && isLoadingLink}
                      disabled={isLoadingLink}
                      onClick={
                        instantLinkValue
                          ? () => copyLinkToClipboard(instantLinkValue)
                          : () => getInstantLink()
                      }
                    >
                      {!isMobile && !isLoadingLink && (
                        <Link fontSize="small" sx={{ mr: 1 }} />
                      )}
                      {instantLinkValue ? "Copy link" : "Generate"}
                    </LoadingButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Divider
            sx={{
              [`.${dividerClasses.wrapper}`]: {
                color: palette.text.secondary,
                fontSize: 12,
                lineHeight: "12px",
              },
            }}
          >
            OR
          </Divider>
        </>
      )}
      <Grid
        container
        spacing={1.5}
        sx={{
          pt: hideInstantLink ? 0 : isMobile ? 2 : 3,
          [`.${formHelperTextClasses.root}`]: {
            ml: 0,
          },
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Controller
            name="recipients"
            control={control}
            render={({ field, fieldState, formState }) => {
              const errors = formState.errors[field.name] || [];
              return (
                <AppAutocomplete<string, true>
                  id="recipients-input"
                  label="Enter Recipients"
                  multiple
                  scrollToLast
                  value={field.value}
                  options={recipientsOptions}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={
                    errors?.length && errors.length > 0
                      ? "Emails must be valid"
                      : " "
                  }
                  getOptionFromInput={(inputValue) => inputValue}
                  onInputSubmit={({ isExisting, option }) => {
                    if (!isExisting) {
                      setRecipientsOptions((prev) => [option, ...prev]);
                    }
                    const i = field.value.indexOf(option);
                    if (i < 0) {
                      field.onChange([...field.value, option]);
                    } else {
                      const next = [...field.value];
                      next.splice(i, 1);
                      field.onChange(next);
                    }
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Controller
            name="subject"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  id="subject-input"
                  label="Subject"
                  fullWidth
                  placeholder="Subject"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Controller
            name="note"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  id="note-input"
                  label="Note"
                  fullWidth
                  multiline
                  rows={isMobile ? 4 : 6}
                  placeholder="Note"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || "200"}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Box>
        <Alert
          severity="info"
          sx={{
            mt: 1,
            px: 1,
            [`.${alertClasses.icon}`]: {
              mr: 1,
            },
          }}
        >
          Sharing is for internal research and analysis only.
        </Alert>
      </Box>
    </Stack>
  );
}
