import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { pageNotFoundRoute } from "src/pages/PageNotFound/PageNotFound.route";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { PublicEventSingle } from "src/components/PublicEventSingle/PublicEventSingle";
import { PageLoader } from "src/components/PageLoader";
import { useEventDetailsPublic } from "src/api/useEventDetailsPublic";
import { PublicSharedEventPathParams } from "./PublicSharedEvent.route";

export function PublicSharedEventPage() {
  const { reportId = "", eventId = "" } =
    useParams<PublicSharedEventPathParams>();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(powerSearchResultClipRoute.makeUrl({ eventId }));
    }
  }, [isAuthenticated, eventId, navigate]);

  const eventsDetailWrapperData = useEventDetailsPublic({
    params: {
      path: {
        source: "report",
        sourceId: reportId,
        eventId,
      },
    },
  });

  const { data } = eventsDetailWrapperData;

  useEffect(() => {
    if (eventsDetailWrapperData?.isFetched && !data?.eventDetails) {
      navigate(pageNotFoundRoute.path);
    }
  }, [eventsDetailWrapperData.isFetched, data?.eventDetails, navigate]);

  if (eventsDetailWrapperData.isLoading) {
    <PageLoader />;
  }

  if (!data?.eventDetails) {
    return null;
  }

  return (
    <PublicEventSingle
      eventData={data?.eventDetails}
      isLoading={eventsDetailWrapperData.isLoading}
      routeRoot="Share"
      bannerData={data?.bannerData}
    />
  );
}
