import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { QueryDefinition } from "src/models/QueryDefinition";
import { reportScratchEventsKey } from "./useReportScratchEvents";
import { reportScratchMatches } from "./useReportScratch";

const apiKey: keyof paths = "/api/Report/scratch/append-from-query";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Parameters = Endpoint["parameters"];

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  query: QueryDefinition;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useReportScratchAppendFromQuery({
  options,
  params,
}: {
  options?: RequestOptions;
  params?: Parameters;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ query }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey, params);
      const body: RequestBody = query;

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: [
          reportScratchEventsKey,
          {
            query: {
              from: 0,
              size: 1,
            },
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: reportScratchMatches,
      });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
  });
}
