import { useRef, useState } from "react";
import { ExpandMore, Info } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Stack,
  Typography,
  colors,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { AdvertisementPicker } from "src/components/AdvertisementPicker/AdvertisementPicker";
import { ProgramTagPicker } from "src/components/ProgramTagPicker/ProgramTagPicker";
import { PopperInfo } from "src/components/PopperInfo/PopperInfo";
import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { AdvertisementFilterNotice } from "src/components/AdvertisementPicker/AdvertisementFilterNotice";
import { useOpenState } from "src/utils/useOpenState";
import { AdvertisementInfoDialog } from "./AdvertisementInfoDialog";

export const MoreOptionsSection = ({ loading }: { loading?: boolean }) => {
  const { breakpoints, palette } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("md"));
  const accordionPaddingValue = isSmallScreen ? 0 : 3;

  const { formState, control } = useFormContext<
    WatchQueryKeywordFormValues | WatchQueryCustomFormValues
  >();
  const { isSubmitting } = formState;
  const isBusy = isSubmitting || loading;

  const advertisementInfoDialog = useOpenState();

  const [anchorElInfo, setAnchorElInfo] = useState<null | HTMLElement>(null);

  const moreOptionsSection = useRef<null | HTMLDivElement>(null);
  const scrollToMoreOptionsSection = () => {
    setTimeout(() => {
      if (moreOptionsSection.current && isSmallScreen) {
        moreOptionsSection.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 250);
  };

  const handleClickInfo = (e: any) => {
    setAnchorElInfo(e.currentTarget);
  };

  const handleCloseInfo = () => {
    setAnchorElInfo(null);
  };

  const openInfo = Boolean(anchorElInfo);

  return (
    <Paper
      sx={{
        width: "100%",
        minHeight: "68px",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: isSmallScreen ? 0 : "8px",
      }}
    >
      <Accordion
        sx={{
          pl: accordionPaddingValue,
          pr: accordionPaddingValue,
          width: "100%",
        }}
        ref={moreOptionsSection}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: "68px", background: "transparent !important" }}
          onClick={scrollToMoreOptionsSection}
        >
          <Typography color={palette.primary.main}>More options</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: palette.background.paper,
          }}
        >
          <Grid container spacing={1.5} mb={2}>
            {/* Advertisement */}
            <Grid item xs={12}>
              <Typography
                variant={isSmallScreen ? "body2" : "subtitle1"}
                fontWeight={600}
                display="flex"
                alignItems="center"
                height={36.5}
              >
                Advertisement
                <Info
                  onClick={
                    isSmallScreen
                      ? advertisementInfoDialog.show
                      : handleClickInfo
                  }
                  sx={{
                    ml: 1,
                    color: colors.blueGrey[200],
                    cursor: "pointer",
                  }}
                />
                {isSmallScreen ? (
                  <AdvertisementInfoDialog
                    isOpen={advertisementInfoDialog.isOpen}
                    onClose={advertisementInfoDialog.hide}
                  />
                ) : (
                  <PopperInfo
                    open={openInfo}
                    anchorEl={anchorElInfo}
                    title="Advertisement"
                    text="The TVEyes platform introduces a robust and user-centric tool designed to enhance your experience in monitoring and analyzing advertising content across broadcast television and radio channels. This feature-rich popover provides versatile options for managing advertisements with a focus on convenience and customization."
                    onClose={handleCloseInfo}
                  />
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} mb={isSmallScreen ? 0 : 3}>
              <Controller
                name="advertisement"
                control={control}
                render={({ field }) => {
                  return (
                    <Stack>
                      <AdvertisementPicker
                        fullWidth
                        disabled={loading}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        sx={!isSmallScreen ? { width: 528 } : undefined}
                      />
                      {field.value === queryAdvertisementValues.none && (
                        <Stack mt={3}>
                          <AdvertisementFilterNotice />
                        </Stack>
                      )}
                    </Stack>
                  );
                }}
              />
            </Grid>
          </Grid>
          {/* Advertisement */}

          {/* Program title */}
          <Grid container spacing={1.5} mb={2} mt={2}>
            <Grid item xs={12} md={12} lg={12}>
              {isSmallScreen ? (
                <Typography
                  variant="body2"
                  fontWeight={600}
                  lineHeight="24px"
                  mb={1.5}
                >
                  Program Title
                </Typography>
              ) : (
                <Typography variant="subtitle1">Program Title</Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Controller
                name="programInclude"
                control={control}
                render={({ field, fieldState, formState }) => {
                  const { list, logic } = formState.errors[field.name] || {};
                  const message = list?.message || logic?.message || " ";

                  return (
                    <ProgramTagPicker
                      id="program-include-input"
                      label="Include"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      getOptionLabel={(o) => o.name}
                      disabled={isBusy}
                      error={!!fieldState.error}
                      helperText={message}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Controller
                name="programExclude"
                control={control}
                render={({ field, fieldState, formState }) => {
                  const { list, logic } = formState.errors[field.name] || {};
                  const message = list?.message || logic?.message || " ";

                  return (
                    <ProgramTagPicker
                      id="program-exclude-input"
                      label="Exclude"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      getOptionLabel={(o) => o.name}
                      disabled={loading}
                      error={!!fieldState.error}
                      helperText={message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          {/* Program title */}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
