import {
  IconButton,
  IconButtonProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { intercomButtonId } from "src/providers/IntercomProvider";
import { useIntercomButtonState } from "src/api/useIntercomButtonState";
import { IntercomLauncherIcon } from "../icons/IntercomLauncherIcon";
import { intercomDesktopButtonClasses } from "./IntercomDesktopButton.const";

export const IntercomDesktopButton = ({
  isOpen,
  ...props
}: { isOpen: boolean } & Omit<IconButtonProps, "children" | "id">) => {
  const { palette, breakpoints, zIndex } = useTheme();
  const { isShown } = useIntercomButtonState();
  const downXl = useMediaQuery(breakpoints.down("xl"));

  return isShown ? (
    <IconButton
      className={
        isOpen && downXl ? intercomDesktopButtonClasses.hideButton : ""
      }
      sx={{
        width: 48,
        zIndex: zIndex.drawer,
        height: 48,
        bgcolor: palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        bottom: "18px",
        right: isOpen && downXl ? "-26px" : "28px",
        transition: "transform 500ms",
        [`&.${intercomDesktopButtonClasses.hideButton}:hover`]: {
          transform: "translateX(-48px)",
          transition: "transform 500ms",
        },
        "&:hover": {
          transform: "scale(1.1)",
          transition: "transform 250ms cubic-bezier(0.33, 0.00, 0.00, 1.00)",
          bgcolor: palette.primary.main,
        },
      }}
      {...props}
      id={intercomButtonId}
      children={
        <IntercomLauncherIcon
          sx={{
            color: "#FFF",
            width: 24,
            height: 24,
          }}
        />
      }
    />
  ) : null;
};
